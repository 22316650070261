import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Paragraph from '@components/elements/Paragraph';
import OverlayNotice from '@components/containers/OverlayNotice';

import { closeInformationNotice } from '@redux/actions/ui';

import useRehydrated from '@utils/useRehydrated';

// TODO: Fetch the message from the API so that it can be updated by admins
const MESSAGE = '';

const InformationNotice = () => {
  const dispatch = useDispatch();

  const showInformationNotice = useSelector(({ ui }) => ui.showInformationNotice);
  const rehydrated = useRehydrated();

  const onClick = useCallback(() => dispatch(closeInformationNotice), [dispatch]);

  if (rehydrated && showInformationNotice && MESSAGE) {
    return (
      <OverlayNotice onClick={onClick}>
        <Paragraph>{MESSAGE}</Paragraph>
      </OverlayNotice>
    );
  }
  return null;
};

export default InformationNotice;
