import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UAParser from 'ua-parser-js';

import Paragraph from '@components/elements/Paragraph';
import Anchor from '@components/elements/Anchor';
import OverlayNotice from '@components/containers/OverlayNotice';

import { closeBrowserNotice } from '@redux/actions/ui';

const unsupportedBrowsers = [
  'IE',
];

const BrowserNotice = ({ showBrowserNotice, dispatchCloseBrowserNotice }) => {
  const [isNotSupported, setIsNotSupported] = useState(false);

  useEffect(() => {
    const ua = UAParser(global.navigator.userAgent);
    setIsNotSupported(unsupportedBrowsers.includes(ua.browser.name));
  }, []);

  return showBrowserNotice && isNotSupported ? (
    <OverlayNotice onClick={dispatchCloseBrowserNotice}>
      <Paragraph>Det verkar som att du använder en gammal webbläsare som vi inte stödjer.</Paragraph>
      <Paragraph>För att kunna uppleva vår hemsida på bästa sätt rekommenderar vi dig att använda någon av följande webbläsare: <Anchor underlined href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank" rel="noopener">Edge</Anchor>, <Anchor underlined href="https://www.google.com/chrome/" target="_blank" rel="noopener">Chrome</Anchor>, <Anchor underlined href="https://support.apple.com/downloads/safari" target="_blank" rel="noopener">Safari</Anchor>, <Anchor underlined href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener">Firefox</Anchor> eller <Anchor underlined href="https://www.opera.com/download" target="_blank" rel="noopener">Opera</Anchor>.</Paragraph>
    </OverlayNotice>
  ) : null;
};

BrowserNotice.propTypes = {
  showBrowserNotice: PropTypes.bool.isRequired,
  dispatchCloseBrowserNotice: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ui }) => ({
  showBrowserNotice: ui.showBrowserNotice,
});
const mapDispatchToProps = {
  dispatchCloseBrowserNotice: closeBrowserNotice,
};
export default connect(mapStateToProps, mapDispatchToProps)(BrowserNotice);
