import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { showModal } from '@redux/actions/ui';
import types from '@components/modals/types';

import { cookiesNames, getCookie, setCookie } from '@utils/cookies';
import { setupStore } from '@redux/createStore';

const CookieNotice = ({ silentCookies }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (silentCookies) {
      setCookie(cookiesNames.EXPERIENCE_COOKIES_ALLOWED, true);
      setupStore();
    } else {
      const hasAnsweredCookiePreferences = getCookie(cookiesNames.ANSWERED_COOKIE_PREFERENCES);
      if (!hasAnsweredCookiePreferences) {
        dispatch(showModal({
          type: types.COOKIES,
          options: { preventClosing: true },
        }));
      }
    }
  }, [dispatch, silentCookies]);

  return null;
};

export default CookieNotice;
