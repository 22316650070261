/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalWrapper from './ModalWrapper';
import types from './types';

const BankIdModal = React.lazy(() => import('./BankIdModal'));
const BankLoginFailedModal = React.lazy(() => import('./BankLoginFailedModal'));
const ErrorModal = React.lazy(() => import('./ErrorModal'));
const InfoModal = React.lazy(() => import('./InfoModal'));
const ValidationFailedModal = React.lazy(() => import('./ValidationFailedModal'));
const MenuModal = React.lazy(() => import('./menu/MenuModal'));
const CookiesModal = React.lazy(() => import('./CookiesModal'));

const modals = {
  [types.BANK_ID]: BankIdModal,
  [types.BANK_LOGIN_FAILED]: BankLoginFailedModal,
  [types.ERROR]: ErrorModal,
  [types.INFO]: InfoModal,
  [types.VALIDATION_FAILED]: ValidationFailedModal,
  [types.MENU]: MenuModal,
  [types.COOKIES]: CookiesModal,
};

const getModal = (modal) => {
  const Modal = modals[modal.type];
  if (!Modal) return null;

  return (
    <React.Suspense fallback={null}>
      <Modal {...modal.props} />
    </React.Suspense>
  );
};

const ModalHost = ({ modal }) => (modal && modal.type ? (<ModalWrapper modalOptions={modal.options}>{getModal(modal)}</ModalWrapper>) : null);

ModalHost.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    options: PropTypes.shape({}),
    props: PropTypes.shape({}),
  }).isRequired,
};

export default connect(({ ui: uiState }) => ({
  modal: uiState.modal,
}))(ModalHost);
