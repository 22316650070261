import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby';

import Anchor from '@components/elements/Anchor';

import Headline from '@components/elements/Headline';

import getUrl from '@utils/getUrl';
import useIsLoggedIn from '@utils/useIsLoggedIn';

import { logout } from '@redux/actions/auth';
import urls from '@constants/urls';
import { hideModal } from '@redux/actions/ui';

const LoginAnchor = () => {
  const isLoggedIn = useIsLoggedIn();
  const dispatch = useDispatch();

  const handleClick = useCallback((e) => {
    if (isLoggedIn) {
      dispatch(logout());
    } else {
      e.preventDefault();
      navigate(getUrl(urls.LANDING));
    }
    dispatch(hideModal());
  }, [isLoggedIn, dispatch]);

  return (
    <Anchor
      onClick={handleClick}
    >
      <Headline as="h6" fontSize="tiny">{isLoggedIn ? 'Logga ut' : 'Logga in'}</Headline>
    </Anchor>
  );
};

export default LoginAnchor;
