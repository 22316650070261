/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import style from '@constants/style';

export const StyledIcon = styled('img')`
  margin-top: ${(props) => (props.$marginTop ? style.margin[props.$marginTop] : 'auto')};
  margin-bottom: ${(props) => (props.$marginBottom ? style.margin[props.$marginBottom] : 'auto')};
  margin-right: ${(props) => (props.$marginRight ? style.margin[props.$marginRight] : 'auto')};
  margin-left: ${(props) => (props.$marginLeft ? style.margin[props.$marginLeft] : 'auto')};
  ${({ $margin }) => $margin && `margin: ${style.margin[$margin]};`}

  flex-shrink: 0;

  height: ${(props) => props.$iconHeight || 'auto'};
  width: ${(props) => props.$iconWidth || 'auto'};

  ${({ $circle }) => ($circle ? 'border-radius: 50%' : '')};
  ${({ $rotate }) => ($rotate ? `transform: rotate(${$rotate}deg)` : '')};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};

  ${({
    $top, $right, $bottom, $left,
  }) => (!!$top || !!$right || !!$bottom || !!$left ? `
    position: absolute;
    ${$top ? `top: ${$top};` : ''}
    ${$right ? `right: ${$right};` : ''}
    ${$bottom ? `bottom: ${$bottom};` : ''}
    ${$left ? `left: ${$left};` : ''}
  ` : '')}

  ${style.focusVisibleMixin}

  ${({ $fillColour, theme, $headerIcon }) => {
    if ($fillColour) return `path { fill: ${style.colours[$fillColour]}; }`;
    if ($headerIcon) return `path { fill: ${theme.headerIconColour}; }`;
    return '';
  }};
`;

StyledIcon.propTypes = {
  $circle: PropTypes.bool,
  $rotate: PropTypes.number,
  $top: PropTypes.string,
  $right: PropTypes.string,
  $left: PropTypes.string,
  $bottom: PropTypes.string,
  $iconHeight: PropTypes.string,
  $iconWidth: PropTypes.string,
  $marginTop: PropTypes.string,
  $marginBottom: PropTypes.string,
  $marginLeft: PropTypes.string,
  $marginRight: PropTypes.string,
  $margin: PropTypes.string,
  $fillColour: PropTypes.string,
  $headerIcon: PropTypes.bool,
};

StyledIcon.defaultProps = {
  $circle: false,
  $rotate: 0,
  $top: null,
  $right: null,
  $left: null,
  $bottom: null,
  $iconHeight: null,
  $iconWidth: null,
  $marginTop: undefined,
  $marginBottom: undefined,
  $marginLeft: undefined,
  $marginRight: undefined,
  $margin: undefined,
  $fillColour: undefined,
  $headerIcon: false,
};

const ENTER = 13;
const SPACE = 32;
const clickKeyCodes = [ENTER, SPACE];

const getSafelyWrappedSvg = (Svg) => React.forwardRef(({
  iconHeight,
  iconWidth,
  circle,
  fillColour,
  headerIcon,
  ...props
}, ref) => <Svg {...props} ref={ref} />);

const Icon = React.forwardRef(({
  className,
  icon,
  alt,
  'aria-label': ariaLabel,
  onClick,
  iconHeight,
  iconWidth,
  circle,
  rotate,
  top,
  right,
  left,
  bottom,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  margin,
  fillColour,
  headerIcon,
  id,
}, ref) => {
  const onKeyDown = useCallback((e) => {
    if (clickKeyCodes.includes(e.keyCode)) onClick(e);
  }, [onClick]);
  const eventHandlers = onClick ? {
    onClick,
    onKeyDown,
    tabIndex: '0',
    role: 'button',
  } : {};

  const styleProps = {
    $iconHeight: iconHeight,
    $iconWidth: iconWidth,
    $circle: circle,
    $rotate: rotate,
    $top: top,
    $right: right,
    $left: left,
    $bottom: bottom,
    $marginTop: marginTop,
    $marginBottom: marginBottom,
    $marginLeft: marginLeft,
    $marginRight: marginRight,
    $margin: margin,
    $fillColour: fillColour,
    $headerIcon: headerIcon,
  };

  const domProps = {
    id,
    alt,
    'aria-label': ariaLabel,
    className,
  };

  return ((!icon || typeof icon === 'string')
    ? <StyledIcon ref={ref} src={icon} {...eventHandlers} {...styleProps} {...domProps} />
    : <StyledIcon ref={ref} as={getSafelyWrappedSvg(icon)} {...eventHandlers} {...styleProps} {...domProps} />);
});

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.string,
  ]),
  alt: PropTypes.string,
  'aria-label': PropTypes.string,
  onClick: PropTypes.func,
  iconHeight: PropTypes.string,
  iconWidth: PropTypes.string,
  circle: PropTypes.bool,
  rotate: PropTypes.number,
  top: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
  bottom: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  margin: PropTypes.string,
  fillColour: PropTypes.string,
  headerIcon: PropTypes.bool,
};

Icon.defaultProps = {
  className: undefined,
  icon: undefined,
  alt: '',
  'aria-label': undefined,
  onClick: undefined,
  iconHeight: null,
  iconWidth: null,
  circle: false,
  rotate: 0,
  top: null,
  right: null,
  left: null,
  bottom: null,
  marginTop: undefined,
  marginBottom: undefined,
  marginLeft: undefined,
  marginRight: undefined,
  margin: undefined,
  fillColour: undefined,
  headerIcon: false,
};

export default Icon;
