import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import GlobalStyle from '@components/GlobalStyles';

import style from '@constants/style';
import ModalHost from '@components/modals';

import CookieNotice from '@components/modules/CookieNotice';
import BrowserNotice from '@components/modules/BrowserNotice';
import InformationNotice from '@components/modules/InformationNotice';

import { HEADER_HEIGHT } from '@components/modules/HeaderStyled';

import useRehydrated from '@utils/useRehydrated';

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  z-index: ${style.zLevels.normal};
  color: ${style.colours.primaryDark};
  background-color: ${({ theme }) => theme.pageBackground || theme.background};
  font-size: ${style.fontSize.regular};

  padding-top: ${HEADER_HEIGHT.mobile};
  min-height: calc(100vh - ${HEADER_HEIGHT.mobile});
  ${style.media.laptop`
    padding-top: ${HEADER_HEIGHT.laptop};
    min-height: calc(100vh - ${HEADER_HEIGHT.laptop});
  `}
`;

const MinimalPage = ({
  children,
}) => {
  const rehydrated = useRehydrated();

  useEffect(() => {
    if (!rehydrated) return;

    if (global.scrollTo) global.scrollTo(0, 0);
  }, [rehydrated]);

  return (
    <StyledPage>
      <ModalHost />
      <GlobalStyle />
      <Helmet>
        <meta name="robots" content="noindex" />
        <link rel="stylesheet" media="all" type="text/css" href="https://use.typekit.net/yhj0sxi.css" />
        {/* NOTE: Temprorary disable google translate. Issue: https://github.com/facebook/react/issues/11538 */}
        <html lang="sv" translate="no" />
        <meta name="google" content="notranslate" />
        <meta charset="UTF-8" />
      </Helmet>
      {children}
      <CookieNotice silentCookies />
      <InformationNotice />
      <BrowserNotice />
    </StyledPage>
  );
};

MinimalPage.propTypes = {
  children: PropTypes.node.isRequired,
};

MinimalPage.defaultProps = {
};

export default MinimalPage;
