import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';

import style from '@constants/style';

import Button from '@components/elements/Button';

const textWidth = {
  laptop: '40rem',
  desktop: '44rem',
};

const containerPadding = {
  mobile: `${style.padding.big} ${style.padding.regular}`,
  tablet: `${style.padding.big} ${style.padding.hugeer}`,
  laptop: style.padding.big,
};

const SPACE_BETWEEN_TEXT_BUTTON = style.margin.huge;

const CONTAINER_HEIGHT = '10vh';

export const positions = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

const StyledOverlayContent = styled('div')`
  * {
    font-size: ${style.fontSize.small};
  }
  margin: 0 0 ${style.margin.regular};
  text-align: center;

  ${style.media.laptop`
    margin: 0;
    margin-right: ${SPACE_BETWEEN_TEXT_BUTTON};
    max-width: ${textWidth.laptop};
  `}
  ${style.media.desktop`
    max-width: ${textWidth.desktop};
  `}
`;

const StyledOverlayNotice = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: fixed;
  ${({ position }) => (position === positions.TOP ? 'top: 0;' : '')};
  ${({ position }) => (position === positions.BOTTOM ? 'bottom: 0;' : '')};
  left: 0;
  right: 0;

  background-color: ${rgba(style.colours.secondaryLightGray4, 0.95)};
  color: ${style.colours.secondaryDarkGray1};
  min-height: ${CONTAINER_HEIGHT};
  z-index: ${style.zLevels.modal};
  padding: ${containerPadding.mobile};

  ${style.media.tablet`
    padding: ${containerPadding.tablet};
  `}

  ${style.media.laptop`
    padding: ${containerPadding.laptop};
    flex-direction: row;
  `}
`;

const OverlayNotice = ({
  buttonLabel,
  onClick,
  children,
  position,
}) => (
  <StyledOverlayNotice position={position}>
    <StyledOverlayContent>
      {children}
    </StyledOverlayContent>
    <Button onClick={onClick}>{buttonLabel}</Button>
  </StyledOverlayNotice>
);

OverlayNotice.propTypes = {
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(Object.values(positions)),
};

OverlayNotice.defaultProps = {
  buttonLabel: 'Jag förstår',
  position: 'top',
};

export default OverlayNotice;
