import urls from '@constants/urls';
import { useLocation } from '@reach/router';
import getUrl from './getUrl';

const useIsLoggedIn = () => {
  const { pathname } = useLocation();

  return pathname.startsWith(getUrl(urls.HOME));
};

export default useIsLoggedIn;
