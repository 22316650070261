import styled from 'styled-components';
import style from '@constants/style';
import { StyledAnchor } from '@components/elements/Anchor';

export const HEADER_HEIGHT = {
  mobile: '4.5rem',
  laptop: '5.5rem',
};

export const HEADER_PADDING = {
  mobile: {
    vertical: style.padding.regular,
    horizontal: style.padding.regular,
  },
  tablet: {
    vertical: style.padding.regular,
    horizontal: style.padding.big,
  },
  laptop: {
    vertical: style.padding.medium,
    horizontal: style.padding.bigger,
  },
};

export const HeaderItemsStyled = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${StyledAnchor} {
    display: none;

    ${style.media.laptop`
      display: flex;
    `}
  }
`;

const HeaderStyled = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  justify-content: space-between;
  align-items: center;

  padding: ${HEADER_PADDING.mobile.vertical} ${HEADER_PADDING.mobile.horizontal};
  height: calc(${HEADER_HEIGHT.mobile} - 2 * ${HEADER_PADDING.mobile.vertical});
  width: calc(100% - 2 * ${HEADER_PADDING.mobile.horizontal});

  background-color: ${({ theme }) => theme.headerBackground || theme.background};
  ${(props) => !props.isOnTop && `box-shadow: ${style.shadows}`};

  z-index: ${style.zLevels.header};

  ${style.media.tablet`
    width: calc(100% - 2 * ${HEADER_PADDING.tablet.horizontal});
    padding: ${HEADER_PADDING.tablet.vertical} ${HEADER_PADDING.tablet.horizontal};
  `}

  ${style.media.laptop`
    height: calc(${HEADER_HEIGHT.laptop} - 2 * ${HEADER_PADDING.laptop.vertical});
    width: calc(100% - 2 * ${HEADER_PADDING.laptop.horizontal});
    padding: ${HEADER_PADDING.laptop.vertical} ${HEADER_PADDING.laptop.horizontal};
  `}
`;

export default HeaderStyled;
