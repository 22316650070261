import React from 'react';
import PropTypes from 'prop-types';

import logoShort from '@assets/img/header/moank_logo_short.svg?inline';

import Icon from '@components/elements/Icon';

import FlexBox from '@components/containers/FlexBox';

const MoankLogo = ({ onClick, alt }) => {
  const icon = logoShort;
  return (
    <FlexBox>
      <Icon
        icon={icon}
        onClick={onClick}
        alt={alt}
        headerIcon
      />
    </FlexBox>
  );
};

MoankLogo.propTypes = {
  onClick: PropTypes.func,
  alt: PropTypes.string.isRequired,
};

MoankLogo.defaultProps = {
  onClick: undefined,
};

export default MoankLogo;
